import numbro from 'numbro';
import {FC} from 'react';

import {useUser} from 'context/user';
import {formatCurrency} from 'core/locale';
import {CURRENCY_CODE} from 'settings';

type FormatCurrencyProps = {
    number?: number | string,
    options?: {decimals?: number;};
    numbroOptions?: numbro.Format;
};

export const FormatCurrency: FC<FormatCurrencyProps> = ({number, options, numbroOptions}) => {
    const {user} = useUser();
    if (!user?.permissions.viewPrices) return <>--- {CURRENCY_CODE}</>;
    if (typeof number === 'string') number = +number;
    if (number === undefined) return <>-</>;
    return <>{formatCurrency(number, options, numbroOptions)}</>;
};
