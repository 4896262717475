import {ProductCategoryAggregate} from 'components/tables/products-table';
import {
    MinimalQuotedPaymentMethodFragment,
    MinimalQuotedProductFragment,
    ProductPriceModifierFragment,
    ProductTaxType,
    QuotedPaymentMethodFormInput,
    QuotedPaymentMethodFragment,
    QuoteFragment,
} from 'generated/graphql';
import * as settings from 'settings';

// Helpers
const applyMultiplier = (
    value: number,
    multiplier: number,
    enableDiscount: boolean,
): number => {
    if (!enableDiscount && multiplier < 1) return value;
    return (multiplier > 0) ? (value * multiplier) : value;
};

// Price Modifier
export const getPriceModifier = (priceModifiers: ProductPriceModifierFragment[], billingType: string) => {
    const priceModifier = priceModifiers?.find(pm => pm.billingType === billingType);
    if (!priceModifier) throw new Error(`Missing price modifier for selected billing type (${billingType})`);
    return priceModifier;
};

// Unit

export const calculateProductUnitPrice = (price: number): number => {
    return Number(price.toFixed(Number(settings.MAX_DECIMALS)));
};

export const calculateProductUnitPriceForDealer = (price: number, commissionMultiplier: number): number => {
    return Number((price * commissionMultiplier).toFixed(Number(settings.MAX_DECIMALS)));
};

// Total
export const calculateProductTotalPrice = (price: number, quantity: number, taxes?: {name: string, amount: number;}[]): number => {
    return (price * quantity + (taxes ? taxes.reduce((total, tax) => tax.name === 'DIFAL' || tax.name === 'ICMS_ST_FRONTEIRA' ? total : total + tax.amount, 0) : 0));
};

export const calculateProductTotalPriceForDealer = (price: number, commissionMultiplier: number, quantity: number, taxes?: {name: string, amount: number;}[]): number => {
    return calculateProductUnitPriceForDealer(price * quantity + (taxes ? taxes.reduce((total, tax) => total + tax.amount, 0) : 0), commissionMultiplier);
};

// List
export const calculateProductsTotalPrice = (products: MinimalQuotedProductFragment[], productTaxes?: ProductTaxType[]): number => {
    return products.reduce((total, product) => total + calculateProductTotalPrice(
        product.price,
        product.quantity,
        productTaxes?.find(productTax => productTax.productExternalId === product.externalId)?.taxes,
    ), 0);
};

export const calculateProductsTotalPriceNegotiated = (products: {price: number, quantity: number, externalId: string;}[], multiplier: number | undefined, productTaxes: ProductTaxType[] | undefined): number => {
    return products.reduce((total, product) => total + calculateProductTotalPrice(
        product.price * (multiplier || 1),
        product.quantity,
        productTaxes?.find(productTax => productTax.productExternalId === product.externalId)?.taxes,
    ), 0);
};

export const calculateProductsTotalPriceNegotiatedForDealer = (products: {price: number, quantity: number, commissionMultiplier: number;}[], multiplier?: number): number => {
    return products.reduce((total, product) => total + calculateProductTotalPriceForDealer(
        product.price * (multiplier || 1),
        product.commissionMultiplier,
        product.quantity,
    ), 0);
};

export const calculateProductsTotalPriceForDealer = (products: MinimalQuotedProductFragment[], productTaxes?: ProductTaxType[]): number => {
    return products.reduce((total, product) => total + calculateProductTotalPriceForDealer(
        product.price,
        product.commissionMultiplier,
        product.quantity,
        productTaxes?.find(productTax => productTax.productExternalId === product.externalId)?.taxes,
    ), 0);
};

export const calculateProductsTotalPriceByCategory = (
    products: MinimalQuotedProductFragment[],
    productTaxes?: {productExternalId: string, taxes: {name: string, amount: number;}[];}[],
): number => {
    return products.reduce((total, product) => total + calculateProductTotalPrice(
        product.price,
        product.quantity,
        productTaxes?.find(productTax => productTax.productExternalId === product.externalId)?.taxes,
    ), 0);
};

export const calculateProductsTotalPriceForDealerByCategory = (products: MinimalQuotedProductFragment[]): number => {
    return products.reduce((total, product) => total + calculateProductTotalPriceForDealer(
        product.price,
        product.commissionMultiplier,
        product.quantity,
    ), 0);
};

export const calculateCategoriesTotalPrice = (categories: ProductCategoryAggregate[]): number => {
    return categories.reduce((total, category) => total + category.total, 0);
};

export const calculateCategoriesTotalPriceForDealer = (categories: ProductCategoryAggregate[]): number => {
    return categories.reduce((total, category) => total + category.totalDealer, 0);
};

export const calculateProductQuantities = (products: MinimalQuotedProductFragment[]): number => {
    return products.reduce((total, product) => total + product.quantity, 0);
};

export const calculateTotalTaxes = (productTaxes: ProductTaxType[] | undefined, products: {externalId: string; quantity: number;}[]): number => {
    return products.reduce((total, product) => {
        let totalTaxes = productTaxes?.find(productTax => productTax.productExternalId === product.externalId)?.taxes.reduce((total, tax) => total + tax.amount, 0);
        return total + (totalTaxes || 0);
    }, 0);
};

export const calculateTotalTaxesForPaymentMethod = (productTaxes: ProductTaxType[] | undefined, products: {externalId: string; quantity: number;}[]): number => {
    return products.reduce((total, product) => {
        const totalTaxes = productTaxes
            ?.find(productTax => productTax.productExternalId === product.externalId)?.taxes
            .reduce((total, tax) => tax.name === 'DIFAL' || tax.name === 'ICMS_ST_FRONTEIRA' ? total : total + tax.amount, 0);
        return total + (totalTaxes || 0);
    }, 0);
};

export const calculateDifalTaxesForPaymentMethod = (productTaxes: ProductTaxType[] | undefined, products: {externalId: string; quantity: number;}[]): number => {
    return products.reduce((total, product) => {
        const totalTaxes = productTaxes
            ?.find(productTax => productTax.productExternalId === product.externalId)?.taxes
            .reduce((total, tax) => tax.name === 'DIFAL' || tax.name === 'ICMS_ST_FRONTEIRA' ? total + tax.amount : total, 0);
        return total + (totalTaxes || 0);
    }, 0);
};

export const getMultiplierToApply = (multiplier: number, extraMultiplier: number, commissionMultiplier: number, dealer?: boolean) => {
    return multiplier >= 1
        ? (dealer ? commissionMultiplier : 1) * multiplier * (1 - (1 - extraMultiplier))
        : (dealer ? commissionMultiplier : 1) * (multiplier - (1 - extraMultiplier));
};

export const calculateMultiplierToNegotiatedPrice = (quote: Partial<QuoteFragment>, paymentMethodSelected?: Partial<QuotedPaymentMethodFragment>, dealer?: boolean) => {
    const paymentMethod = quote.sale ? quote.sale.paymentMethod : paymentMethodSelected;
    return paymentMethod
        ? getMultiplierToApply(
            paymentMethod.multiplier || 1,
            paymentMethod.extraMultiplier || 1,
            paymentMethod.dealerCommission || 1,
        )
        : 1;
};

// Payment methods
export const calculatePaymentMethodTotalPrice = (
    products: MinimalQuotedProductFragment[],
    paymentMethod: MinimalQuotedPaymentMethodFragment,
    productTaxes?: ProductTaxType[] | undefined,
    dealer?: boolean,
): number => {
    return products.reduce((total, product) => {

        const {enableDiscount} = product;
        const {multiplier, extraMultiplier} = paymentMethod;

        const multiplierToApply = getMultiplierToApply(multiplier, extraMultiplier, product.commissionMultiplier, dealer);

        let price = applyMultiplier(
            product.price,
            multiplierToApply,
            enableDiscount,
        );

        price = calculateProductTotalPrice(
            price,
            product.quantity,
            productTaxes?.find(productTax => productTax.productExternalId === product.externalId)?.taxes,
        );
        return total + price;
    }, 0);
};

export const calculatePaymentMethodTotalPriceDisplay = (
    products: Pick<MinimalQuotedProductFragment, 'enableDiscount' | 'price' | 'commissionMultiplier' | 'quantity'>[],
    paymentMethod: QuotedPaymentMethodFragment,
): number => {
    return products.reduce((total, product) => {

        const {enableDiscount} = product;
        const {multiplier, extraMultiplier} = paymentMethod;

        const multiplierToApply = getMultiplierToApply(multiplier, extraMultiplier, product.commissionMultiplier);

        return total + applyMultiplier(product.price, multiplierToApply, enableDiscount) * product.quantity;
    }, (paymentMethod.taxes ? paymentMethod.taxes.reduce((total, tax) => tax.name === 'DIFAL' || tax.name === 'ICMS_ST_FRONTEIRA' ? total : total + tax.amount, 0) : 0));
};

export const calculateTaxesAggregate = (productTaxes: ProductTaxType[] | undefined, products: MinimalQuotedProductFragment[]): {name: string, amount: number;}[] => {
    const taxes = productTaxes?.flatMap(productTax => {
        return productTax.taxes.map(tax => {
            let _tax = {...tax};
            _tax.amount = tax.amount;
            return _tax;
        });
    });
    const taxesByName = taxes?.reduce((taxesByName: any, tax: any) => ({
        ...taxesByName,
        [tax.name]: [...(taxesByName[tax.name] || []), tax],
    }), {});
    if (taxesByName) {
        return Object.entries(taxesByName).map(([name, taxes]: any) => {
            return {
                name: name,
                amount: taxes.reduce((total: number, tax: {amount: number;}) => total + tax.amount, 0),
            };
        });
    }
    return [];
};

export const calculateTaxesPaymentMethodAggregate = (
    currentPaymentMethods: QuotedPaymentMethodFormInput[],
    paymentMethod: MinimalQuotedPaymentMethodFragment,
    productTaxes: ProductTaxType[] | undefined,
    products: MinimalQuotedProductFragment[],
) => {
    return currentPaymentMethods.map(pm => {
        if (paymentMethod.name === pm.name) pm.taxes = calculateTaxesAggregate(productTaxes, products);
        return pm;
    });
};

export const calculatePaymentMethodTotalPriceWithTax = (
    products: MinimalQuotedProductFragment[],
    paymentMethod: MinimalQuotedPaymentMethodFragment,
    productTaxes: ProductTaxType[] | undefined,
): number => {
    return calculatePaymentMethodTotalPrice(products, paymentMethod, productTaxes);
};

export const calculatePaymentMethodTotalPriceWithTaxDisplay = (
    products: Pick<MinimalQuotedProductFragment, 'enableDiscount' | 'price' | 'commissionMultiplier' | 'quantity'>[],
    paymentMethod: QuotedPaymentMethodFragment,
): number => {
    return calculatePaymentMethodTotalPriceDisplay(products, paymentMethod);
};

export const calculateDealerCommissionPaymentMethod = (
    products: MinimalQuotedProductFragment[],
    paymentMethod: QuotedPaymentMethodFragment | QuotedPaymentMethodFormInput,
): number => {
    return calculatePaymentMethodTotalPrice(
        products,
        paymentMethod as QuotedPaymentMethodFragment)
        * 0.87
        * ((paymentMethod.dealerCommission || 0) / 100);
};

export const calculateCommissionOnTotalForJDEPaymentMethod = (
    products: MinimalQuotedProductFragment[],
    paymentMethod: QuotedPaymentMethodFragment | QuotedPaymentMethodFormInput,
): string => {
    return (calculateDealerCommissionPaymentMethod(products, paymentMethod)
        * 100
        / calculatePaymentMethodTotalPriceWithTaxDisplay(
            products,
            paymentMethod as QuotedPaymentMethodFragment,
        )
    ).toFixed(2);
};

export const calculatePaymentMethodTotalTax = (
    products: MinimalQuotedProductFragment[],
    productTaxes: ProductTaxType[] | undefined,
): number => {
    return calculateTotalTaxesForPaymentMethod(productTaxes, products as any);
};
